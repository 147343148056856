import { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  loginAction,
  userWalletActions,
} from "core/redux/account/account.action";
import { connect, useSelector } from "react-redux";
import { localStorage, utils } from "core/helper";
import { GET_USER_BAL, PEARBEE_VERIFY } from "core/service/api.url.service";
import { services } from "core/service";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import NotFound from "components/common/not-found";
import mixpanel from "mixpanel-browser";
import { Loading } from "components/common/loading";
const PostLogin = lazy(() => import("../postlogin"));
// import Services from "core/service/services";

const Container = (props) => {
  // let tokenId = "4dc872b37582184cc1872af286ab5821"; // mix-panel-tokenid
  // let tokenId = "e2fe2f16dada2f1c4e2d7b678654ee5d"; // mix-panel-tokenid

  const { loginAction, userWalletActions } = props;
  const isLoggedIn = useSelector((state) => state.account?.isLoggedIn);
  const authUser = useSelector((state) => state?.account?.authUser);
  const reloadWallet = useSelector((state) => state.account?.reloadWallet);
  const [isComponentMounted, setComponentMounted] = useState(false);
  const [getTokenParams] = useSearchParams();
  const [validateToken, setValidateToken] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  let tokenId = process.env.REACT_APP_MIX_PANEL; // mix-panel-tokenid
  mixpanel.init(tokenId, {
    debug: false,
    track_pageview: true,
    persistence: "localStorage",
    userId: authUser?.data?.user_id,
    externalUserId: authUser?.data?.external_user_id,
  });

  let tokenUrl = getTokenParams.get("token");
  // let refreshTokenUrl = getTokenParams.get("refreshToken");

  const loadPearbeeVerfify = async (token,) => {
    try {
      // const category = await Services.post(`${CATEGORYBYID}${id}`);
      setVerifyLoading(true);
      let urlPlbOrVeeraCheck = PEARBEE_VERIFY;
      const authUrl = 'https://api-stan.pearbee.com/api/stan/stanLogin';


      const resp = await axios.post(authUrl, {}, {
        headers: {
          "token": token,
          // "refresh-token": refreshToken ? refreshToken : undefined,
        },
      });
      if (resp?.status) {
        const { data } = resp;

        loginAction({
          isLoggedIn: true,
          token: data.data.token,
          authUser: data.data,
        });
        setValidateToken(true);
        setVerifyLoading(false);
      } else {
        setValidateToken(false);
        setVerifyLoading(false);
      }
    } catch (err) {
      setValidateToken(false);
      setVerifyLoading(false);
    }
  };

  useEffect(() => {
    if (isComponentMounted) {
      let token = getTokenParams.get("token");
      // let refreshToken = getTokenParams.get("refreshToken");
      if (!isLoggedIn && (token)) {
        loadPearbeeVerfify(token);
      }
    }
  }, [getTokenParams, isLoggedIn, isComponentMounted]);

  useEffect(() => {
    const handleChange = async () => {
      await getUserBalance();
    };
    if (isLoggedIn) {
      handleChange();
    }
  }, [isLoggedIn, reloadWallet]);

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  const checkSessionlogin = async () => {
    const token = localStorage.getAuthToken();
    if (token === "") {
      return false;
    }
    const authUser = localStorage.getAuthUser();
    loginAction(authUser);
  };

  useEffect(() => {
    const handleChange = async () => {
      await checkSessionlogin();
      utils.mixPannelEvent("home_page", "HOME_PAGE", "home_page");
    };
    if (isComponentMounted) {
      handleChange();
    }
  }, [isComponentMounted]);

  const getUserBalance = async () => {
    const resp = await services.get(GET_USER_BAL);
    if (resp?.result) {
      userWalletActions(resp?.result?.[0]);
    }
  };

  const LoadFallback = () => {
    return (
      <div className="pearbee_fallback_loading_wrap">
        <Loading variant="light" />
      </div>
    );
  };

  // useEffect(() => {
  //   if (isLoggedIn && (tokenUrl || refreshTokenUrl)) {
  //     getTokenParams.delete("token");
  //     getTokenParams.delete("refreshTokenUrl");
  //   }
  // }, [tokenUrl, refreshTokenUrl, isLoggedIn]);

  return (
    <div
      data-typography="poppins"
      data-theme-version="light"
      data-layout="vertical"
      data-nav-headerbg="color_1"
      data-headerbg="color_1"
      data-sidebar-style="full"
      data-sibebarbg="color_1"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-container="wide"
      direction="ltr"
      data-primary="color_1"
      data-sibebartext="color_1"
    >
      {/* <Suspense fallback={<LoadFallback />}>
        {isLoggedIn && <PostLogin />}
        {!isLoggedIn && !validateToken && <NotFound />}
      </Suspense> */}
      <Suspense fallback={<LoadFallback />}>
        {!isLoggedIn && verifyLoading && <LoadFallback />}
        {isLoggedIn && <PostLogin />}
        {!isLoggedIn && (!tokenUrl) && !validateToken && <NotFound />}
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (authUser) => dispatch(loginAction(authUser)),
    userWalletActions: (wallet) => dispatch(userWalletActions(wallet)),
  };
};

export default connect(null, mapDispatchToProps)(Container);
