export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const REMOVE_LOGGED_IN_USER = "REMOVE_LOGGED_IN_USER";
export const PROFILE_ACTION = "PROFILE_ACTION";
export const SET_LOGGED_USER_DATA = "SET_LOGGED_USER_DATA";
export const USER_WALLET_ACTIONS = "USER_WALLET_ACTIONS";
export const RELOAD_WALLET_ACTIONS = "RELOAD_WALLET_ACTIONS";
export const TON_LOGGED_ACTION = "TON_LOGGED_ACTION";
export const ADD_PURCHASE_ITEMS_DATA = "ADD_PURCHASE_ITEMS_DATA";
export const MOBILE_NUMBER_ACTION = "MOBILE_NUMBER_ACTION";
export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";

